<template>
  <div class='container'>
    <kcHeader></kcHeader>
    <div class="content">
        <h5>{{helpObj.title}}</h5>
        <div v-html="helpObj.content"></div>
    </div>
  </div>
</template>

<script>
import kcHeader from '@components/kcHeader'
import {help} from "@assets/api/help/help";
export default {
  name:'',
  components: {kcHeader},

  data () {
    return {
      helpObj:{}
    };
  },

  computed: {},

  created(){
    this.getHelpData()
  },

  methods: {
    getHelpData(){
      help().then(res=>{
        if(res.code == 1){
          this.helpObj = res.data
          console.log('help',this.helpObj)
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
  },

}

</script>
<style lang="css" >
p >img{
  width: 100%;
}

</style>
<style lang='less' scoped>
.container{
    background: #F4F6F8;
}
.content{
    margin: 0 auto;
    width: 1200px;
    min-height: calc(100vh - 70px);
    padding: 20px 0;
    h5{
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        padding: 0 0 20px;
        border-bottom: 1px solid #E9E9E9;
    }
    pre{
        padding: 15px 0;
        white-space: pre-wrap;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
      img{
        width: 100%;
      }
    }
}
</style>